<messages>["../../PasswordReset/index"]</messages>
<messages>["../UserCreateUpdate"]</messages>

<!--
================================================================================
  Template (HTML)
================================================================================
-->

<template>
  <base-layout mw1>
    <v-col cols="12">
      <v-card class="search-result">
        <!-- toolbar for selected item operations -->
        <v-toolbar
          v-if="!!selectedItems.length"
          class="selectionSupport ma-4"
          floating>
          <v-toolbar-title v-text="$tc ('user.list.selected', selectedItems.length, {count: selectedItems.length})"/>

          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                icon
                class="mx-2"
                color="error"
                v-on="on"
                @click="onDeleteUsers (selectedItems)">
                <v-icon>delete</v-icon>
              </v-btn>
            </template>
            <span v-text="$t('general.button.delete')">
              Delete
            </span>
          </v-tooltip>
          <v-tooltip top>
            <template #activator="{ on }">
              <v-btn
                color="accent"
                icon
                :loading="isLoadingResetMulti"
                v-on="on"
                @click="onPasswordResetMulti (selectedItems)">
                <v-icon>security</v-icon>
              </v-btn>
            </template>
            <span v-text="this.$t ('passwordReset.title')">
              Reset
            </span>
          </v-tooltip>
        </v-toolbar>

        <v-card-title primary-title>
          <v-row justify="space-between">
            <v-col cols="12" sm="6">
              <div>
                <div
                  v-t="'title.list'"
                  class="text-h5"/>
                <div
                  v-t="'title.listSub'"
                  class="cgwng-subheading"/>
              </div>
            </v-col>
            <!-- filter -->
            <v-col v-if="frontendListOperations" cols="12" sm="6">
              <v-text-field
                v-model.trim="search"
                append-icon="search"
                :label="$t ('general.label.search')"
                single-line
                hide-details
                clearable
                @keyup.esc="search = ''"/>
            </v-col>
          </v-row>
        </v-card-title>

        <!-- list -->
        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="users"
            :page.sync="pagination.page"
            :items-per-page.sync="pagination.rowsPerPage"
            :sort-by.sync="pagination.sortBy"
            :sort-desc.sync="pagination.descending"
            :footer-props="footerProps"
            :server-items-length="totalCount"
            :loading="loading"
            :no-data-text="noDataText (loading)"
            :no-results-text="noResultsText (loading)"
            :value="selectedItems"
            selected-key="id"
            show-select
            class="elevation-1"
            @input="selectCB">
            <template #headerCell="props">
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <span
                    class="primary--text"
                    v-on="on">
                    {{ props.header.text }}
                  </span>
                </template>
                <span>{{ props.header.text }}</span>
              </v-tooltip>
            </template>
            <template #item="props">
              <tr>
                <td>
                  <v-checkbox
                    :value="props.isSelected"
                    dense
                    class="mx-0 my-2 pa-0"
                    color="primary"
                    hide-details
                    @change="props.select"/>
                </td>
                <td>
                  <router-link
                    :to="{name: 'user.view', params: {id: props.item.id}}"
                    v-text="props.item.name"/>
                </td>
                <td>{{ props.item.displayName }}</td>
                <td>{{ props.item.emailAddress }}</td>
                <td v-if="isClientColumnVisible">
                  <client-link
                    v-if="props.item.clientId"
                    :id="props.item.clientId"
                    :key="props.item.id + '-' + props.item.clientId"/>
                  <template v-else>
                    {{ emptyMark }}
                  </template>
                </td>
                <td>
                  <action-buttons
                    :value="isActionButtonsActive (props.item.id)"
                    :buttons="getActionButtons (props.item)"
                    @input="state => setActionButtonsActive (props.item.id) (state)"
                    @clicked="processActionButton"/>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-t="'general.button.refresh'"
            :to="{name: 'user.list'}"/>
          <v-btn
            v-if="mayManageSubEntities || mayManageAllEntities"
            v-t="'general.button.create'"
            color="primary"
            :to="{name: 'user.create'}"/>
        </v-card-actions>

        <!-- delete confirmation dialog -->
        <base-dialog
          v-if="showDeleteConfirmationDialog"
          v-model="showDeleteConfirmationDialog"
          mw0
          close-on-esc>
          <v-card>
            <v-card-title>
              <div
                class="text-h5"
                v-text="$t ('user.delete.title')"/>
            </v-card-title>
            <v-card-text
              v-text="$tc (
                'user.delete.message',
                itemsToDelete.length, {
                  id: itemsToDelete[0].id,
                  count: itemsToDelete.length
                })"/>
            <v-card-actions>
              <v-spacer/>
              <v-btn
                v-t="'general.button.cancel'"
                text
                @click.native="showDeleteConfirmationDialog = false"/>
              <v-btn
                v-t="'general.button.delete'"
                color="error"
                @click.native="deleteUsers ()"/>
            </v-card-actions>
          </v-card>
        </base-dialog>

        <selection-dialog
          v-model="showSelectDialog"
          :some-selected="selectedItems.length > 0"
          @select="select"/>
      </v-card>
    </v-col>
  </base-layout>
</template>

<!--
================================================================================
  Logic (JavaScript)
================================================================================
-->

<script>
  import {mapGetters, mapMutations, mapActions} from 'vuex'

  import {EmptyMark} from '@/app/utils/string'

  import paginationMixins from '@/app/core/mixins/PaginationComponent'
  import actionButtonsHelper from '@/app/core/mixins/ActionButtonsHelper'
  import listSelectionMixin from '@/app/core/mixins/ListSelectionHelper'

  import ActionButtons from '@/app/core/components/ActionButtons'
  import BaseDialog from '@/app/core/components/BaseDialog'
  import BaseLayout from '@/app/core/components/BaseLayout'
  import ClientLink from '@/app/core/components/ClientLink'
  import SelectionDialog from '@/app/core/components/SelectionDialog'

  export default {
    name: 'UserList',

    components: {
      ActionButtons,
      BaseDialog,
      BaseLayout,
      ClientLink,
      SelectionDialog
    },

    mixins: [paginationMixins, actionButtonsHelper, listSelectionMixin],

    data () {
      return {
        // flag, which allows list filter (search) and client-side
        // sorting/paging
        frontendListOperations: true,
        search: '',
        cachedUsers: [],
        users: [],
        totalCount: 0,
        speedDial: {},
        selectedItems: [],
        itemsMatchingSearch: [],
        itemsToDelete: [],
        showDeleteConfirmationDialog: false,
        loading: true,
        isLoadingResetMulti: false,
        isLoadingImpersonate: false,
        impersonateUserIds: null
      }
    },

    computed: {
      // mix the state into computed with object spread operator
      ...mapGetters ('auth', [
        'userId',
        'mayManageAllEntities',
        'mayManageSubEntities',
        'permissions',
        'hasAllOfPermissions',
        'isImpersonated',
        'isSameClient',
        'mayManageForeignObjects',
        'isClientless'
      ]),

      ...mapGetters ({
        mayViewClient: 'auth/mayViewClient'
      }),

      isClientColumnVisible () {
        return this.mayManageForeignObjects
      },

      emptyMark () {
        return EmptyMark
      },

      cachedItems () {
        return this.cachedUsers
      },

      items () {
        return this.users
      },

      headers () {
        return [
          {
            text: this.$t ('user.list.header.name'),
            value: 'name'
          },
          {
            text: this.$t ('user.list.header.displayName'),
            value: 'displayName'
          },
          {
            text: this.$t ('user.list.header.email'),
            value: 'emailAddress'
          },
          ...(this.isClientColumnVisible)
            ? [{
              text: this.$t ('user.list.header.client'),
              value: 'clientId'
            }]
            : [],
          {
            text: this.$t ('general.label.actions'),
            sortable: false
          }
        ]
      },

      actingUserId () {
        return this.userId.effectiveUserId || this.userId.userId
      }
    },

    watch: {
      search (term) {
        this.list ()
        this.$router.replace ({
          name: this.$route.name,
          params: {
            ...this.$route.params
          },
          query: {
            ...this.$route.query,
            search: term
          }
        })
      },

      '$route.query.search': {
        handler (term) {
          if (term) {
            this.search = term
          }
        },
        immediate: true
      }
    },

    created () {
      this.pagination.sortBy = 'name'
      this.pagination.rowsPerPage = 10
      // this is a workaround to enforce data retrieve from BE
      // even if the pagination and other request parameters are not changed
      // (otherwise data are not fetched on page reload and browser "back" in history)
      // TODO, FIXME: need better solution to prevent request duplication
      this.onPaginationStateChanged (this.internalPaginationState)
    },

    methods: {
      ...mapMutations ({
        displaySuccessMessage: 'notification/setSuccess'
      }),
      ...mapActions ({
        fetchData: 'request/fetchData',
        impersonate: 'auth/impersonateUser'
      }),

      /**
       * calculate action buttons for specified item
       *
       * @param item      item in the list for which action buttons should be
       *                  calculated
       */
      getActionButtons (item) {
        const view = [{
          action: 'view',
          itemId: item.id,
          icon: 'visibility',
          tooltip: this.$t ('general.button.view')
        }]

        const edit = [...(
          this.hasAllOfPermissions (item.userPerms) || this.isClientless
            ? [{
              action: 'edit',
              itemId: item.id,
              icon: 'edit',
              tooltip: this.$t ('general.button.edit')
            }]
            : []
        )]

        const resetPassword = [{
          action: 'passwordReset',
          userName: item.name,
          icon: 'security',
          tooltip: this.$t ('passwordReset.title')
        }]

        const impersonate = [
          // the impersonation button should be available for not already
          // impersonated admins
          ...(
            (this.hasAllOfPermissions (item.userPerms) || this.isClientless) && !this.isImpersonated &&
            !this.isSameClient (item.clientId) && (!item.clientId || this.mayViewClient (item.clientId))
              ? [{
                action: 'impersonate',
                actionArg: item,
                isLoading: this.impersonateUserIds === item.id && this.isLoadingImpersonate,
                disabled: this.isImpersonated,
                icon: 'supervisor_account',
                tooltip: this.$t ('user.impersonate.title')
              }]
              : []
          )]

        const deleteAction = [{
          action: 'delete',
          actionArg: [item],
          icon: 'delete',
          tooltip: this.$t ('general.button.delete'),
          color: 'error'
        }]

        return [
          ...view,
          ...edit, // only with permission
          ...resetPassword,
          {divider: true},
          ...deleteAction,
          ...impersonate.length ? [{divider: true}] : [],
          ...impersonate // only admins
        ]
      },

      list () {
        this.listUsers ()
      },

      async requestUserList (params) {
        const data = await this.fetchData ({
          op: 'user/list',
          params
        })

        return [data.list || [], data.totalCount || 0]
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters (client-side filter, sorting, slicing)
       */
      performListOperation () {
        // filter
        if (this.search) {
          const searchNormalized = this.search.trim ().toLocaleLowerCase ()
          this.users = this.cachedUsers.filter (o => {
            let match
            for (const prop in o) {
              if (o.hasOwnProperty (prop)) {
                switch (typeof o[prop]) {
                  case 'string':
                    match = o[prop].toLocaleLowerCase ()
                      .includes (searchNormalized)
                    break

                  case 'number':
                    match = o[prop] === Number.parseInt (searchNormalized)
                    break
                }

                if (match) {
                  break
                }
              }
            }
            return match
          })
        } else {
          this.users = this.cachedUsers.slice ()
        }
        this.totalCount = this.users.length
        // sort
        if (this.pagination.sortBy) {
          this.users = this.sort (
            this.users, this.pagination.sortBy, this.pagination.descending)
        }

        this.itemsMatchingSearch = [...this.users]

        // slice
        if (this.pagination.page > 0 && this.pagination.rowsPerPage > 0) {
          const start = (this.pagination.page - 1) * this.pagination.rowsPerPage
          const end = start + this.pagination.rowsPerPage
          this.users = this.users.slice (start, end)
        }
      },

      sort (items, column, descending) {
        if (descending === null) return items
        const collator = new Intl.Collator (
          undefined,
          {numeric: true, sensitivity: 'base'}
        )

        switch (column) {
          case 'name':
            return items.sort ((i1, i2) => descending
              ? collator.compare (i2.name, i1.name)
              : collator.compare (i1.name, i2.name))

          case 'displayName':
            return items.sort ((i1, i2) => descending
              ? collator.compare (i2.displayName, i1.displayName)
              : collator.compare (i1.displayName, i2.displayName))

          case 'emailAddress':
            return items.sort ((i1, i2) => {
              if (!i2.emailAddress) {
                return descending ? -1 : 1
              }
              if (!i1.emailAddress) {
                return descending ? 1 : -1
              }

              return descending
                ? i2.emailAddress.localeCompare (i1.emailAddress)
                : i1.emailAddress.localeCompare (i2.emailAddress)
            })

          case 'clientId':
            return items.sort ((i1, i2) => descending
              ? i2.clientId - i1.clientId
              : i1.clientId - i2.clientId)

          case 'actions':
            console.warn ('sorting by action is not supported')
            break
        }
      },

      /**
       * populate data table properties according to current search and
       * pagination parameters. If {@code this.frontendListOperations} is set,
       * then operation is performed on the client side.
       */
      async listUsers () {
        this.loading = true

        if (this.frontendListOperations) {
          // if not cached => get from server
          if (!(this.cachedUsers.length > 0)) {
            [this.cachedUsers] = await this.requestUserList ({})
            this.totalCount = this.cachedUsers.length
          }
          // filter, sorting, slicing cached items
          this.performListOperation ()
        } else {
          [this.users, this.totalCount] =
            await this.requestUserList (this.getPaginationForRequest ())
        }

        this.loading = false
      },

      /**
       * process specified action button according to it's properties
       *
       * @param button {Object}     button to be processed
       */
      processActionButton (button) {
        switch (button.action) {
          case 'view':
            this.$router.push ({
              name: 'user.view',
              params: {id: button.itemId}
            })
            break
          case 'edit': {
            const editRoute = this.actingUserId === button.itemId
              ? {
                name: 'user.profile'
              }
              : {
                name: 'user.edit',
                params: {id: button.itemId}
              }
            this.$router.push (editRoute)
            break
          }
          case 'delete':
            this.onDeleteUsers (button.actionArg)
            break
          case 'passwordReset':
            this.resetPassword (button.userName)
            break
          case 'impersonate':
            this.onImpersonateUser (button.actionArg)
            break
          default:
            console.warn ('Unhandled button clicked:', button)
            break
        }
      },

      /**
       * impersonate specified user
       *
       * @param user      user to be impersonated
       */
      async onImpersonateUser (user) {
        this.isLoadingImpersonate = true
        this.impersonateUserIds = user.id
        const success = await this.impersonate (user.id)

        // go to start page
        if (success) {
          this.displaySuccessMessage (
            this.$t ('user.impersonate.success', {user: user.name}))
          this.$router.push ({name: 'dashboard'})
        }

        this.isLoadingImpersonate = false
        this.impersonateUserIds = null
      },

      /**
       * send 'resetPassword' request to server for specified user
       *
       * @param name        name of user, which password should be reset
       */
      resetPassword (name) {
        return this.fetchData ({
          op: 'resetPassword',
          params: {identity: name},
          cb: () => {
            this.displaySuccessMessage (
              this.$t ('user.passwordReset.success', {name}))
          }
        })
      },

      /**
       * Send password reset to multiple users
       *
       * @param clients {Array}         users where the password should be
       *                                resetted
       */

      onPasswordResetMulti (clients) {
        this.isLoadingResetMulti = true
        const num = clients.length
        const ids = clients.map (e => e.id)
        this.fetchData ({
          op: 'resetPasswordMulti',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (
              this.$tc ('user.passwordReset.successMulti', num))
            this.selectedItems = []
          },
          cbFinal: () => {
            this.isLoadingResetMulti = false
          }
        })
      },

      /**
       * show delete confirmation dialog
       *
       * @param itemsToDelete   items to be deleted
       */
      onDeleteUsers (itemsToDelete) {
        this.itemsToDelete = itemsToDelete
        this.showDeleteConfirmationDialog = true
      },

      /**
       * delete users, specified by {@code this.itemsToDelete}
       * (see onDeleteUsers)
       *
       * @param successCallback     function to be called in success case
       */
      deleteUsers (successCallback = this.afterDelete) {
        // hide confirmation dialog
        this.showDeleteConfirmationDialog = false
        const ids = this.itemsToDelete.map (e => e.id)

        this.fetchData ({
          op: 'user/delete',
          params: {ids},
          cb: () => {
            this.displaySuccessMessage (
              this.$tc (
                'user.deleted',
                ids.length, {
                  id: ids[0],
                  count: ids.length
                }))
            successCallback (ids)
          }
        })
      },

      /**
       * state corrections after delete operation
       *
       * @param deletedIds    IDs of items, which was deleted
       */
      afterDelete (deletedIds) {
        // filter out deleted from selected items
        this.selectedItems =
          this.selectedItems.filter (it => !deletedIds.includes (it.id))
        // reset items to delete
        this.itemsToDelete = []
        // reset cache
        this.cachedUsers = []
        // get the list from server
        this.listUsers ()
      }
    }
  }
</script>
